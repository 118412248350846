import { Collapse } from 'reactstrap';
import { NavLink, useLocation, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Accessibility,
  Region,
  SalesType,
  SideBarRoute,
  SideBarSubRoute,
  UserRole,
  UserStatus,
  VehicleBodyType,
} from 'app/models';
import { useAppSelector } from 'app/hooks/store';
import { RootState } from 'store';
import { ROUTES } from 'app/constants/Routes';

interface Props {
  route: SideBarRoute;
}

export const MenuItem = ({ route }: Props) => {
  const location = useLocation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { profile } = useAppSelector((rootState: RootState) => rootState.auth);
  const navigate = useNavigate();

  const subRoutesWithPermission = (
    originalSubRouteArray: SideBarSubRoute[],
  ) => {
    let subRoutesArray: SideBarSubRoute[] = [...originalSubRouteArray];

    if (
      !profile ||
      profile.role === UserRole.warehouse ||
      profile.status === UserStatus.已停用
    ) {
      navigate('/login');
      return [];
    } else {
      if (profile.role === UserRole.admin) {
        return originalSubRouteArray;
      } else {
        if (!profile.UserPermission?.salesType.includes(SalesType.AFTERSALES)) {
          subRoutesArray = [
            ...subRoutesArray.filter(
              subRoute =>
                subRoute.path !== ROUTES.BRAND_E_BRAND &&
                subRoute.path !== ROUTES.REPORT_E_REPORT &&
                subRoute.path !== ROUTES.SURVEY_E_SURVEY,
            ),
          ];
        }
        if (!profile.UserPermission?.salesType.includes(SalesType.SALES)) {
          subRoutesArray = [
            ...subRoutesArray.filter(
              subRoute =>
                subRoute.path !== ROUTES.BRAND_PC_HK &&
                subRoute.path !== ROUTES.BRAND_PC_MACAU &&
                subRoute.path !== ROUTES.BRAND_CV_HK &&
                subRoute.path !== ROUTES.BRAND_CV_MACAU &&
                subRoute.path !== ROUTES.REPORT_PC_HK &&
                subRoute.path !== ROUTES.REPORT_PC_MACAU &&
                subRoute.path !== ROUTES.REPORT_CV_HK &&
                subRoute.path !== ROUTES.REPORT_CV_MACAU &&
                subRoute.path !== ROUTES.SURVEY_PC_HK &&
                subRoute.path !== ROUTES.SURVEY_PC_MACAU &&
                subRoute.path !== ROUTES.SURVEY_CV_HK &&
                subRoute.path !== ROUTES.SURVEY_CV_MACAU,
            ),
          ];
        }
        if (!profile.UserPermission?.cities.includes(Region['Hong Kong'])) {
          subRoutesArray = [
            ...subRoutesArray.filter(
              subRoute =>
                subRoute.path !== ROUTES.BRAND_PC_HK &&
                subRoute.path !== ROUTES.BRAND_CV_HK &&
                subRoute.path !== ROUTES.REPORT_PC_HK &&
                subRoute.path !== ROUTES.REPORT_CV_HK &&
                subRoute.path !== ROUTES.SURVEY_PC_HK &&
                subRoute.path !== ROUTES.SURVEY_CV_HK,
            ),
          ];
        }
        if (!profile.UserPermission?.cities.includes(Region['Macau'])) {
          subRoutesArray = [
            ...subRoutesArray.filter(
              subRoute =>
                subRoute.path !== ROUTES.BRAND_PC_MACAU &&
                subRoute.path !== ROUTES.BRAND_CV_MACAU &&
                subRoute.path !== ROUTES.REPORT_PC_MACAU &&
                subRoute.path !== ROUTES.REPORT_CV_MACAU &&
                subRoute.path !== ROUTES.SURVEY_PC_MACAU &&
                subRoute.path !== ROUTES.SURVEY_CV_MACAU,
            ),
          ];
        }
        if (
          !profile.UserPermission?.accessibility?.includes(Accessibility.Call)
        ) {
          subRoutesArray = [
            ...subRoutesArray.filter(
              subRoute =>
                subRoute.path !== ROUTES.CALL_CENTER &&
                subRoute.path !== ROUTES.CALL_CENTER_AFTER_SERVICE &&
                subRoute.path !== ROUTES.CALL_CENTER_APPOINTMENT_REMINDER &&
                subRoute.path !== ROUTES.CALL_CENTER_EXPORT &&
                subRoute.path !== ROUTES.CALL_CENTER_FREE_CHECK &&
                subRoute.path !== ROUTES.CALL_CENTER_SEARCH &&
                subRoute.path !== ROUTES.CALL_CENTER_SPECIAL &&
                subRoute.path !== ROUTES.CALL_CENTER_STATISTIC,
            ),
          ];
        }
        if (
          !profile.UserPermission?.vehicleBodyType?.includes(VehicleBodyType.CV)
        ) {
          subRoutesArray = [
            ...subRoutesArray.filter(
              subRoute =>
                subRoute.path !== ROUTES.BRAND_CV_HK &&
                subRoute.path !== ROUTES.BRAND_CV_MACAU &&
                subRoute.path !== ROUTES.REPORT_CV_HK &&
                subRoute.path !== ROUTES.REPORT_CV_MACAU &&
                subRoute.path !== ROUTES.SURVEY_CV_HK &&
                subRoute.path !== ROUTES.SURVEY_CV_MACAU,
            ),
          ];
        }
        if (
          !profile.UserPermission?.vehicleBodyType?.includes(VehicleBodyType.PC)
        ) {
          subRoutesArray = [
            ...subRoutesArray.filter(
              subRoute =>
                subRoute.path !== ROUTES.BRAND_PC_HK &&
                subRoute.path !== ROUTES.BRAND_PC_MACAU &&
                subRoute.path !== ROUTES.REPORT_PC_HK &&
                subRoute.path !== ROUTES.REPORT_PC_MACAU &&
                subRoute.path !== ROUTES.SURVEY_PC_HK &&
                subRoute.path !== ROUTES.SURVEY_PC_MACAU,
            ),
          ];
        }
      }
      return subRoutesArray;
    }
  };

  useEffect(() => {
    const routeRegex = new RegExp(route.regex);
    // if (!location.pathname.includes(route.path)) {
    //   setOpen(false);
    // }
    if (!routeRegex.test(location.pathname)) {
      setOpen(false);
    }
    if (route.subRoutes && route.subRoutes.length) {
      for (let i = 0; i < route.subRoutes.length; i++) {
        const regex = new RegExp(route.subRoutes[i].regex);
        if (regex.test(location.pathname)) {
          setOpen(true);
          break;
        }
      }
    }
  }, [location.pathname, route.path, route.subRoutes, route.regex]);

  if (route.subRoutes && route.subRoutes.length) {
    return (
      <li>
        <Link
          to={route.path}
          className={`has-arrow ${
            isOpen ? 'active mm-active active-arrow' : ''
          }`}
          onClick={() => setOpen(!isOpen)}
        >
          {/* {route.iconClass ? <i className={route.iconClass}></i> : null} */}
          {route.icon ? route.icon : null}
          <span>{route.name}</span>
        </Link>

        <Collapse isOpen={isOpen}>
          <ul className="sub-menu">
            {subRoutesWithPermission(route.subRoutes).map((subRoute, index) => (
              <li key={index}>
                <NavLink
                  to={subRoute.path}
                  className={({ isActive }) => {
                    return isActive ? 'active mm-active' : '';
                  }}
                >
                  {subRoute.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </Collapse>
      </li>
    );
  }
  return (
    <li>
      <NavLink
        to={route.path}
        className={({ isActive }) => (isActive ? 'active mm-active' : '')}
      >
        {/* {route.iconClass ? <i className={route.iconClass}></i> : null} */}
        {route.icon ? route.icon : null}
        <span>{route.name}</span>
      </NavLink>
    </li>
  );
};
